<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">{{ video_info.name }}</div>
        <!-- <img
          src="../static/images/study_bvase.png"
          alt=""
          class="base_title_image"
        /> -->
        <img
          src="../static/fh.png"
          alt=""
          class="base_back_image"
          @click="handleGoBack"
        />
      </div>

      <!-- 视频 :src="video_info.video_file" -->
      <div class="video_content" v-if="video_info.video_file">
        <video
          ref="myVideo"
          :src="video_info.video_file"
          @ended="handleVideoEnd"
          autoplay="autoplay"
          muted="muted"
          controls
          controlslist="nodownload"
          disablePictureInPicture
        ></video>
        <!-- <video
          width="320"
          height="240"
          playsinline
          autoplay="true"
          controlslist="nodownload"
        >
          <source :src="video_info.video_file" type="video/mp4" />
        </video> -->
      </div>
      <div v-if="video_info.outlink" class="ljkh" @click="imgShow = !imgShow">
        立即考核
      </div>
      <!-- 弹窗 -->
      <div class="tanC" v-if="imgShow" @click="imgShow = !imgShow">
        <div class="value">
          <img :src="QRImgUrl" alt="" />
        </div>
      </div>
      <!-- 卡片列表 -->
      <div class="card_list">
        <div
          class="card_item"
          v-for="(item, index) in recommend"
          :key="index"
          @click="handleVideo(item)"
        >
          <img :src="handleGetUrl(item.poster_image)" alt="" srcset="" />
          <div class="video_base_info">
            <span>{{ item.name }}</span>
            <span>播放次数: {{ item.count }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode"; //引入生成二维码插件
import { baseURL } from "../request/index";
import { handleGetArticle, handleGetArticleList } from "../api/index";
export default {
  // name: 'HomeView',
  components: {},
  data() {
    return {
      video_info: {},
      recommend: [],
      imgShow: false,
      QRImgUrl: "",
      QRlink: "https://www.bilibili.com/",
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.handleGetVideoInfo(parseInt(this.$route.query.id));
    }
    if (this.$route.query.type) {
      this.handleGetVideoArticleInfo(this.$route.query.type);
    }
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    handleVideoEnd() {
      console.log("视频播放完成", this.video_info);
      if (this.video_info.outlink) {
        this.imgShow = true;
      }
    },
    getQRcode(outlink) {
      let opts = {
        errorCorrectionLevel: "L", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 4, //二维码留白边距
        width: 256, //宽
        height: 256, //高
        text: "", //二维码内容
        color: {
          dark: "#666666", //前景色
          light: "#fff", //背景色
        },
      };
      QRCode.toDataURL(outlink, opts, (err, url) => {
        console.log(err, "url-------------------------", url);
        if (err) throw err;
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url;
      });
    },
    async handleGetVideoInfo(id) {
      const t = new FormData();
      t.append("id", id);
      // t.append('area', localStorage.getItem('region'))
      const r = await handleGetArticle(t);
      this.video_info = {
        ...r.data.value,
        video_file: baseURL + r.data.value.video_file,
      };
      if (this.video_info.outlink) {
        this.getQRcode(this.video_info.outlink);
      }
    },
    async handleGetVideoArticleInfo(type) {
      const t = new FormData();
      t.append("type", type);
      t.append("page", "1");
      t.append("count", "4");
      const r = await handleGetArticleList(t);
      this.recommend = r.data.list;
    },
    handleGoBack() {
      history.back(-1);
    },
    handleVideo(item) {
      this.handleGetVideoInfo(item.id);
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;
      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.6rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }
      & > .base_title_image {
        margin-top: 0.5rem;
        width: 100%;
      }
      & > .base_back_image {
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.3rem;
        object-fit: contain;
        position: absolute;
        top: 0.75rem;
        left: 0.3rem;
        z-index: 10;
      }
    }
    & > .video_content {
      padding: 0 0.3rem;
      & > video {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    & > .card_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // margin-top: 0.3rem;
      padding: 0.3rem;
      & > .card_item {
        position: relative;
        flex: 1;
        width: calc((100% - 10px) / 2);
        min-width: calc((100% - 10px) / 2);
        max-width: calc((100% - 10px) / 2);
        // height: 100px;
        overflow: hidden;
        line-height: 1;
        margin-bottom: .25rem;
        padding: 0.2rem;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: .15rem;
        background-size: 100% 100% !important;
        // background: url("../static/images/study_item_base.png") no-repeat;
        & > img {
          width: 100%;
          // height: 2rem;
          border-radius: .15rem;
        }
        & > .video_base_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.2rem;
          color: #555;
        }
      }
    }
  }
}

.ljkh {
  width: 90%;
  margin: .3rem auto;
  font-size: .5rem;
  text-align: center;
  background-color: #e91521;
  padding: .2rem 0;
  box-sizing: border-box;
  border-radius: .2rem;
}
.tanC {
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  > .value {
    width: 8rem;
    height: 8rem;
    padding: .3rem;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: .3rem;
    > img {
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
