import request from '../request/index'

/**
 * 测试
 * @returns
 */
export function handleTest() {
  return request.get('/api/demo/test1')
}

export function handleIndexBanner() {
  return request.post('/api/index/getSwiper', {
    group: 'home',
  })
}

export function handleIndexSortType() {
  return request.post('/api/index/getSortType')
}

export function handleIndexFlagList(d) {
  return request.post('/api/index/getFlagList', d)
}

export function handleGetWebCofig() {
  return request.post('/api/index/getSiteConfig')
}

export function handleGetArticleList(d) {
  return request.post('/api/index/getArticleList', d)
}

export function handleGetArticle(d) {
  return request.post('/api/index/getArticle', d)
}

export function handleGetetSortTypeList(d) {
  return request.post('/api/index/getSortTypeList', d)
}

export function handleGetexamGetSort(d) {
  return request.post('/api/exam.exam/getSort', d)
}

export function handleGetTestList(d) {
  return request.post('/api/exam.exam/getTestList', d)
}

export function handleGetTest(d) {
  return request.post('/api/exam.exam/getTest', d)
}

export function GetPdf(d) {
  return request.post('/api/index/getpdf?path=' + d)
}

export function sendTest(d) {
  return request.post('/api/exam.exam/sendTest', d)
}

export function getArticleList(d) {
  return request.post('/api/cms/getArticleList', d)
}

export function getArticle(d) {
  return request.post('/api/cms/getArticle', d)
}

export function getTypeList(d) {
  return request.post('/api/index/getSortTypeList', d)
}
