<template>
  <div>
    <div v-if="loading">加载中...</div>
    <div ref="pdfViewerContainer" class="pdfViewerContainer"></div>
  </div>
</template>

<script>
import * as PDFJS from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
export default {
  name: 'PdfViewer',
  props: {
    /**
     * pdf地址
     */
    pdfUrl: {
      type: String,
      default: '',
    },
    /**
     * cMap的url地址(用于解决中文乱码或中文显示为空白的问题). 该资源的物理路径在: node_modules/pdfjs-dist/cmaps
     * 2.2.228版本可解决iphone14大文件显示不全问题
     */
    cMapUrl: {
      type: String,
      default: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/cmaps/',
    },
    /**
     * pdf缩放比例
     */
    scale: {
      type: Number,
      default: 1,
    },
    /**
     * 携带的特定http请求头
     */
    httpHeaders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pdfDocRef: null,
      loading: false,
    }
  },
  computed: {
    urlObj() {
      return { pdfUrl: this.pdfUrl, cMapUrl: this.cMapUrl }
    },
  },
  watch: {
    urlObj() {
      this.renderPdf()
    },
    scale() {
      this.renderPdf()
    },
  },
  mounted() {
    this.loading = true
    // 创建画布
    this.renderPdf()
    setTimeout(() => {
      console.log(document.querySelector('.pdfViewerContainer'), 'dom done')
      this.loading = false //渲染pdf时会影响这里异步代码执行，在这设置loading结束
    }, 200)
  },
  methods: {
    /**
     * 渲染pdf文件的指定页到画板
     * @param pdfViewerDom 承载pdf画板的dom容器
     * @param pdfDoc pdf文件
     * @param pageNum 需要渲染的页码
     * @param scale 缩放比例
     */
    renderPdfOnePage(pdfViewerDom, pdfDoc, pageNum, scale) {
      // 创建画布
      const canvas = document.createElement('canvas')
      pdfViewerDom.appendChild(canvas)
      // 获取2d上下文
      const context = canvas.getContext('2d')
      pdfDoc.getPage(pageNum).then((page) => {
        let viewport = page.getViewport({ scale: scale })
        let newScale = 375 / viewport.width //这里根据实际情况来，设计稿为宽375的iphone
        viewport = page.getViewport({ scale: newScale }) //按设备宽等比例缩放pdf文件
        const realCanvas = context.canvas
        let outputScale = window.devicePixelRatio || 1 //按像素缩放调节清晰度
        realCanvas.width = Math.floor(viewport.width * outputScale) //将pdf缩放到对应屏幕大小
        realCanvas.height = Math.floor(viewport.height * outputScale)
        realCanvas.style.width = '100%' //显示区域宽度撑满
        realCanvas.style.height = '100%' //显示区域高度撑满
        let transform =
          outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null
        page.render({ canvasContext: context, viewport, transform })
      })
    },
    /**
     * 渲染pdf的画布
     * @param pdfViewerDom 承载pdf画布的dom容器
     * @param pdfDoc pdf文档
     * @param scale 缩放比例
     */
    renderPdfCanvas(pdfViewerDom, pdfDoc, scale) {
      // 清除原来的pdf画布
      pdfViewerDom.innerHTML = ''
      // 获取总页数
      const totalPage = pdfDoc.numPages
      // 获取显示容器
      for (let i = 1; i <= totalPage; i++) {
        // 循环处理pdf的每页
        this.renderPdfOnePage(pdfViewerDom, pdfDoc, i, scale)
      }
    },
    renderPdf() {
      const pdfViewerDom = this.$refs.pdfViewerContainer
      if (this.pdfUrl) {
        // 获取pdf文件
        const pdfLoadingTask = PDFJS.getDocument({
          url: this.pdfUrl,
          withCredentials: true, // 携带凭证
          httpHeaders: this.httpHeaders,
          cMapUrl: this.cMapUrl,
          cMapPacked: true,
        })
        pdfLoadingTask.promise.then((pdfDoc) => {
          if (pdfDoc && pdfViewerDom) {
            // 缓存pdf内容
            this.pdfDocRef = pdfDoc
            this.renderPdfCanvas(pdfViewerDom, pdfDoc, this.scale)
          }
        })
      }
    },
  },
}
</script>

<style scoped></style>
