var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_index"},[_c('img',{attrs:{"src":require("../static/vrbg.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"content_body"},[_vm._m(0),_c('img',{staticClass:"back_image",attrs:{"src":require("../static/fh.png"),"alt":""},on:{"click":_vm.handleBack}}),_c('div',{staticClass:"img_code"},[_c('div',{staticStyle:{"flex":"1"}}),_c('div',{staticClass:"listLeng",staticStyle:{"flex":"3"}},[_vm._v("共"+_vm._s(_vm.list.length)+"题")]),_c('div',{staticClass:"menu_search",staticStyle:{"flex":"1"}},[_c('img',{attrs:{"src":require("../static/images/code.png"),"alt":""},on:{"click":function($event){_vm.is_code_open = true}}})])]),(!_vm.is_over)?_c('div',[(_vm.list.length > 0)?_c('div',{staticClass:"content_list"},[_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"sub_content"},[_c('div',{staticClass:"question_type"},[_c('span',[_vm._v(" "+_vm._s(_vm.list[_vm.index].qustion.type === 'multiple' ? '多选题' : '单选题')+" ")])]),_c('span',[_vm._v(_vm._s(_vm.index + 1)+". ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.list[_vm.index].qustion.content)}})])]),_c('div',{staticClass:"item_content_select"},[_c('div',{staticClass:"select_list"},[_c('div',{style:({
                color:
                  _vm.list[_vm.index].qustion.select_user.indexOf('A') > -1
                    ? '#fff'
                    : '#000',
                background:
                  _vm.list[_vm.index].qustion.select_user.indexOf('A') > -1
                    ? '#f04b2b'
                    : '',
              }),on:{"click":function($event){return _vm.hanleSelectOption('A')}}},[_vm._v(" A. "+_vm._s(_vm.list[_vm.index].qustion.A)+" ")]),(_vm.list[_vm.index].qustion.B)?_c('div',{style:({
                color:
                  _vm.list[_vm.index].qustion.select_user.indexOf('B') > -1
                    ? '#fff'
                    : '#000',
                background:
                  _vm.list[_vm.index].qustion.select_user.indexOf('B') > -1
                    ? '#f04b2b'
                    : '',
              }),on:{"click":function($event){return _vm.hanleSelectOption('B')}}},[_vm._v(" B. "+_vm._s(_vm.list[_vm.index].qustion.B)+" ")]):_vm._e(),(_vm.list[_vm.index].qustion.C)?_c('div',{style:({
                color:
                  _vm.list[_vm.index].qustion.select_user.indexOf('C') > -1
                    ? '#fff'
                    : '#000',
                background:
                  _vm.list[_vm.index].qustion.select_user.indexOf('C') > -1
                    ? '#f04b2b'
                    : '',
              }),on:{"click":function($event){return _vm.hanleSelectOption('C')}}},[_vm._v(" C. "+_vm._s(_vm.list[_vm.index].qustion.C)+" ")]):_vm._e(),(_vm.list[_vm.index].qustion.D)?_c('div',{style:({
                color:
                  _vm.list[_vm.index].qustion.select_user.indexOf('D') > -1
                    ? '#fff'
                    : '#000',
                background:
                  _vm.list[_vm.index].qustion.select_user.indexOf('D') > -1
                    ? '#f04b2b'
                    : '',
              }),on:{"click":function($event){return _vm.hanleSelectOption('D')}}},[_vm._v(" D. "+_vm._s(_vm.list[_vm.index].qustion.D)+" ")]):_vm._e()])])]):_c('div',{staticClass:"content_list laoding_d"},[_vm._v("数据加载中")])]):(_vm.type === 'show')?_c('div',{staticClass:"achievement"},[_c('div',{staticStyle:{"color":"#fff"}},[_vm._v("回答正确: "+_vm._s(_vm.over_info.correct)+"题")]),_c('div',{staticStyle:{"color":"#ff0000"}},[_vm._v("回答错误: "+_vm._s(_vm.over_info.error)+"题")]),_c('div',{staticClass:"over_btn",on:{"click":_vm.handleRestTest}},[_c('div',[_vm._v("重新答题")])])]):_c('div',{staticClass:"achievement"},[_c('div',{staticStyle:{"color":"#fff","font-size":"0.8rem","margin-bottom":"0.2rem"}},[_vm._v(" 得分: "+_vm._s(_vm.score)+" ")]),_c('div',{staticStyle:{"font-size":"0.4rem"}},[_vm._v("用时: "+_vm._s(_vm.time_test))]),_c('div',{staticClass:"over_btn",on:{"click":_vm.handleRestTest}},[_c('div',[_vm._v("重新答题")])])]),(_vm.loading)?_c('div',{staticClass:"laoding_d"},[_vm._v("数据提交中")]):_vm._e(),(_vm.is_code_open)?_c('div',{staticClass:"code_qr",on:{"click":function($event){_vm.is_code_open = false}}},[_c('img',{attrs:{"src":_vm.codeqr,"alt":""}})]):_vm._e(),_c('div',{staticClass:"item_content_btn"},[_c('div',{staticClass:"select_list"},[_c('div',{on:{"click":_vm.handleSubTest}},[_vm._v("提交")]),_c('div',{staticStyle:{"font-weight":"bold"},on:{"click":_vm.handleBackQuestion}},[_vm._v(" 上一题 ")]),_c('div',{staticClass:"xiaClass",on:{"click":_vm.handleAddQuestion}},[_vm._v("下一题")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"study_title"},[_c('div',{staticClass:"title"},[_vm._v("答 题")])])
}]

export { render, staticRenderFns }