<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body" ref="tablescroll">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">视 频</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <img
        src="../static/fh.png"
        alt=""
        class="back_image"
        @click="handleBack"
      />

      <div class="swiper">
        <!-- :list="tab_index ? video_quality_list : video_list" -->
        <BannerVideo :list="listListS" @video="handlePlay" />
      </div>
      <!-- 小Tab -->
      <!-- <div class="search_cotnent">
        <img
          src="../static/images/back_base.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search">
          <img src="../static/images/search.png" alt="" />
          <input type="text" placeholder="请输入内容" v-model="search_value" />
        </div>
      </div> -->
      <div class="menu_top_content">
        <div
          class="menu_top_left"
          @click="tab_indexclick(index, i)"
          v-for="(i, index) in typeL"
          :key="index"
        >
          <div
            :style="tab_index === index ? 'color: #e91521;background:#fff' : ''"
          >
            {{ i.name }}
          </div>
        </div>
        <!-- <div class="menu_top_left" @click="tab_index = 0">
          <div :style="tab_index === 0 ? 'color: #e91521;background:#fff' : ''">
            安全视频分类
          </div>
        </div>
        <div class="menu_top_right" @click="tab_index = 1">
          <div :style="tab_index === 1 ? 'color: #e91521;background:#fff' : ''">
            质量视频分类
          </div>
        </div> -->
      </div>
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div class="item" v-for="(item, index) in listListS" :key="index">
          <div class="biaoT slh">{{ item.name }}</div>
          <div class="item_images" @click="handlePlay(item)">
            <img :src="handleGetUrl(item.poster_image)" alt="" />
          </div>
          <!-- <div class="item_content">
            <div class="app_name">{{ item.name }}</div>
            <div class="app_desc">
              {{ item.description }}
            </div>
            <div class="app_size">
              <span>浏览次数: {{ item.count }}</span>
              <span>日期: {{ handleGetTime(item.createtime) }}</span>
            </div>
            <div class="app_open" @click="handlePlay(item)">
              <div>
                <img src="../static/images/open_base.png" alt="" />
              </div>
              <div class="open_text">播放</div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="content_list" v-if="tab_index === 1">
        <!-- 样式1 -->
        <div
          class="item"
          v-for="(item, index) in video_quality_list"
          :key="index"
        >
          <div class="biaoT slh">{{ item.name }}</div>

          <div class="item_images" @click="handlePlay(item)">
            <img :src="handleGetUrl(item.poster_image)" alt="" />
          </div>
          <!-- <div class="item_content">
            <div class="app_name">{{ item.name }}</div>
            <div class="app_desc">
              {{ item.description }}
            </div>
            <div class="app_size">
              <span>浏览次数: {{ item.count }}</span>
              <span>日期: {{ handleGetTime(item.createtime) }}</span>
            </div>
            <div class="app_open" @click="handlePlay(item)">
              <div>
                <img src="../static/images/open_base.png" alt="" />
              </div>
              <div class="open_text">播放</div>
            </div>
          </div> -->
        </div>
      </div>

      <a class="back_top" @click.prevent="handleBackTop">Top</a>
    </div>
  </div>
</template>
<script>
import BannerVideo from "../components/BannerVideo.vue";
import dayjs from "dayjs";
import { baseURL } from "../request/index";
import { handleGetArticleList, getTypeList } from "../api/index";
export default {
  components: {
    BannerVideo,
  },
  name: "CoursewareLearning",
  data() {
    return {
      tab_index: 0,
      video_all: [],
      video_quality_all: [],
      //////////////////
      video_list: [],
      video_quality_list: [],
      search_value: "",
      typeL: [], // 类别
      listListS: [], // 列表数据
    };
  },
  watch: {
    // tab_index: function () {
    //   this.search_value = "";
    //   this.video_list = this.video_all;
    //   this.video_quality_list = this.video_quality_all;
    // },
    search_value: function (e) {
      if (this.tab_index === 0) {
        this.video_list = this.video_all.filter((v) => v.name.indexOf(e) > -1);
      }
      if (this.tab_index === 1) {
        this.video_quality_list = this.video_quality_all.filter(
          (v) => v.name.indexOf(e) > -1
        );
      }
    },
  },
  mounted() {
    // this.handleGetVideo("安全");
    // this.handleGetVideo("质量");
    this.typeList();
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    tab_indexclick(index, i) {
      this.tab_index = index;
      this.handleGetVideo(i.name);
    },
    // 获取分类
    async typeList() {
      const res = await getTypeList({
        type: "video",
        istree: 1,
      });
      if (res.code === 1) {
        this.typeL = res.data.list;
        this.handleGetVideo(this.typeL[0].name);
      }
    },
    async handleGetVideo(sortName) {
      const t = new FormData();
      t.append("page", "1");
      t.append("count", "999");
      t.append("type", "video");
      t.append("sortName", sortName);
      const res = await handleGetArticleList(t);
      this.listListS = res.data.list || [];
      // if (sortName === "安全") {
      //   this.video_list = res.data.list || [];
      //   this.video_all = res.data.list || [];
      // }
      // if (sortName === "质量") {
      //   this.video_quality_list = res.data.list || [];
      //   this.video_quality_all = res.data.list || [];
      // }
    },
    handlePlay(item) {
      this.$router.push(`/video?id=${item.id}&type=${item.type}`);
    },
    handleBack() {
      history.back(-1);
    },
    handleBackTop() {
      this.$nextTick(() => {
        this.$refs.tablescroll.scrollTop = 0;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.9rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .back_image {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }

      & > .menu_search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;

        & > img {
          width: 1rem;
          height: 1rem;
        }

        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          font-size: 0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      height: 1.3rem;
      width: 90%;
      margin: 0.4rem auto;
      border-radius: 0.8rem;
      padding: 0 0.2rem;
      box-sizing: border-box;
      background-color: #e91521;
      display: flex;
      justify-content: space-between;
      align-content: center;
      white-space: nowrap;
      overflow-x: auto;
      // scrollbar-width: thin;
      // scrollbar-color: #888888 #f3f3f3;

      & > .menu_top_left {
        width: 49%;
        font-size: 0.45rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          padding: 0.15rem 0.5rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
        }

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;
        font-size: 0.45rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          padding: 0.15rem 0.5rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
        }
        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.2rem;
      & > .item {
        width: 31%;
        // height: 2.8rem;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        // padding: 0.2rem;
        box-sizing: border-box;
        // margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        // background: url("../static/images/href_right_base.png") no-repeat;
        // overflow: hidden;
        flex-wrap: wrap;
        > .biaoT {
          font-size: 0.3rem;
          width: 100%;
          margin: 0 auto 0.1rem;
          border-radius: 0.4rem;
          background-color: #e91521;
          text-align: center;
          padding: 0.1rem 0.2rem;
          box-sizing: border-box;
        }
        & > .item_images {
          width: 100%;
          height: 100%;
          // background-color: #fff;
          border-radius: 0.2rem;

          & > img {
            width: 100%;
            height: 100%;
            border-radius: 0.2rem;
            object-fit: contain;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: rgb(34, 244, 247);

          & > .app_name {
            font-size: 0.4rem;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            padding: 0.3rem 0;
            box-sizing: border-box;
            font-size: 0.25rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;

            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0.5rem;
            font-size: 0.3rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.6rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url("../static/images/style_k.png") no-repeat;
      }
    }
  }
}

.back_top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.3rem;
  background: #2ca4ff;
  border-radius: 50%;
  font-size: 0.3rem;
  padding: 0.2rem;
  z-index: 111;
  color: #fff;
}

.back_image {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.3rem;
  object-fit: contain;
  position: absolute;
  top: 0.9rem;
  left: 0.3rem;
}
.swiper {
  width: 90%;
  min-height: 5rem;
  margin: 0rem auto;
}
</style>
