<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch('handleGetWebConfig')
  },
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.marquee,
.copy,
.getWidth {
  font-size: 15px !important;
  font-weight: bold !important;
}

.title_content img {
  width: 100% !important;
}

.slh {
  /*让长段文本不换行*/
  white-space: nowrap;
  /*设置文本超出元素宽度部分隐藏*/
  overflow-x: hidden;
  /*设置文本超出部分用省略号显示*/
  text-overflow: ellipsis;
}
.slh2 {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.slider-item {
  height: 5.6rem !important;
}
</style>
