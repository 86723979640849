import Vue from 'vue'
import Vuex from 'vuex'
import { handleGetWebCofig } from '../api/index'
import { baseURL } from '../request/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    web_config: {},
  },
  getters: {},
  mutations: {
    set_web_config(state, t) {
      state.web_config = t
    },
  },
  actions: {
    async handleGetWebConfig(state) {
      const res = await handleGetWebCofig()
      state.commit('set_web_config', {
        ...res.data.value,
        web_logo: baseURL + res.data.value.web_logo,
      })
    },
  },
  modules: {},
})
