<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body" ref="tablescroll">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">用户信息</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <img
        src="../static/fh.png"
        alt=""
        class="back_image"
        @click="handleBack"
      />
      <div class="input_form_content">
        <div class="item_row">
          <div>姓名</div>
          <input type="text" placeholder="请输入姓名" v-model="form.username" />
        </div>
        <div class="item_row">
          <div>工区</div>
          <input
            type="text"
            placeholder="请输入工区"
            v-model="form.work_area"
          />
        </div>
        <div class="item_row">
          <div>工种</div>
          <input type="text" placeholder="请输入工种" v-model="form.job_type" />
        </div>
      </div>
      <div class="question_immediately">
        <div @click="handleSubform">立即答题</div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { baseURL } from '../request/index'
import { getArticleList } from '../api/index'
export default {
  // name: 'HomeView',
  data() {
    return {
      form: {
        username: '', // 姓名
        work_area: '', // 工区
        job_type: '', // 工种
      },
    }
  },

  components: {},
  mounted() {
    this.handleGetVideo()

    this.form.username = localStorage.getItem('username')
    this.form.work_area = localStorage.getItem('work_area')
    this.form.job_type = localStorage.getItem('job_type')
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format('YYYY-MM-DD')
      }
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url
      }
    },
  },
  methods: {
    async handleGetVideo() {
      const t = new FormData()
      t.append('type', 'safe')
      const res = await getArticleList(t)
      this.video_list = res.data.list || []
      this.video_all = res.data.list || []
    },
    handlePlay(item) {
      this.$router.push(`/meeting?id=${item.id}&type=${item.type}`)
    },
    handleBack() {
      history.back(-1)
    },
    handleBackTop() {
      this.$nextTick(() => {
        this.$refs.tablescroll.scrollTop = 0
      })
    },
    handleSubform() {
      if (!this.form.username) {
        alert('请输入姓名')
        return
      }
      if (!this.form.work_area) {
        alert('请输入工区')
        return
      }
      if (!this.form.job_type) {
        alert('请输入工种')
        return
      }
      localStorage.setItem('username', this.form.username)
      localStorage.setItem('work_area', this.form.work_area)
      localStorage.setItem('job_type', this.form.job_type)
      // this.$router.replace(
      //   '/test?id=10&code=ed388990-9484-4f49-af28-825bd2d8db1e&is_source=login'
      // )
      this.$router.push(
        `/test?id=` +
          this.$route.query.id +
          '&code=' +
          this.$route.query.code +
          '&is_source=login' +
          `&uid=${this.$route.query.uid}`
      )
    },
  },
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .back_image {
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 0.3rem;
        object-fit: contain;
        position: absolute;
        top: 0.8rem;
        left: 0.3rem;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }

      & > .menu_search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;

        & > img {
          width: 1rem;
          height: 1rem;
        }

        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          font-size: 0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;

      & > .menu_top_left {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;

      & > .item {
        height: 1.9rem;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.25rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background-color: #fff;
        border-radius: 0.3rem;
        // background: url("../static/images/href_right_base.png") no-repeat;
        overflow: hidden;

        & > .item_images {
          width: 3rem;
          height: 2rem;
          overflow: hidden;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          flex: 1;
          padding-left: 0.2rem;
          color: #333;

          & > .app_name {
            font-size: 0.4rem;
            font-weight: bold;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            padding: 0.3rem 0;
            box-sizing: border-box;
            font-size: 0.25rem;
            line-height: 0.4rem;
            height: 1.6rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;

            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0.2rem;
            font-size: 0.3rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.6rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}

.input_form_content {
  margin-top: 0.4rem;
  & > .item_row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.521rem;
    padding: 0.4rem 1.71rem;
    font-weight: bold;
    & > input {
      padding: 0.3rem;
      border-radius: 4px;
      border: none;
    }
  }
}

.back_image {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.3rem;
  object-fit: contain;
  position: absolute;
  top: 0.8rem;
  left: 0.3rem;
}

.question_immediately {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
  & > div {
    font-size: 0.5rem;
    padding: 0.3rem 1.6rem;
    background: #e91c1d;
    border-radius: 25px;
    font-weight: bold;
  }
}
</style>
