<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">{{ info.description }}</div>
        <video
          v-if="info.video_file"
          class="base_title_image"
          :src="handleGetUrl(info.video_file)"
          autoplay="autoplay"
          muted="muted"
          controls
          controlslist="nodownload"
          disablePictureInPicture
        ></video>
        <img
          src="../static/fh.png"
          alt=""
          class="base_back_image"
          @click="handleGoBack"
        />
      </div>
      <!-- 视频 -->
      <!-- <div class="video_content">
        <img
          src="https://img.zcool.cn/community/01e91d5ecc6a7aa801214d72f8e0fe.png@1280w_1l_2o_100sh.png"
          alt=""
          srcset=""
        />
      </div> -->

      <!-- 监控  v-show="video_view" -->
      <div class="video_content">
        <video
          autoplay="autoplay"
          muted="muted"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          ref="video"
        ></video>
        <!-- 录像 -->
        <video
          :src="video_view"
          autoplay="autoplay"
          muted="muted"
          controls
          controlslist="nodownload"
          disablePictureInPicture
        ></video>
      </div>
      <div class="video_transcribe">
        <div @click="handleStartVideo" v-if="!is_video">开始录制</div>
        <div style="border-color: red; color: red" v-else>录制中</div>

        <div class="tzClass" @click="handleEndVideo">停止录制</div>
      </div>
      <div class="push_video" v-if="video_view">立即上传</div>
      <!-- 卡片列表 -->
      <!-- <div class="card_list">
        <div class="card_item">
          <img
            src="https://img.zcool.cn/community/01e91d5ecc6a7aa801214d72f8e0fe.png@1280w_1l_2o_100sh.png"
            alt=""
            srcset=""
          />
          <div class="video_base_info">
            <span>视频名称</span>
            <span>播放次数: 100</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { getArticle } from "../api/index";
import { baseURL, baseAPIURL } from "../request/index";

// 媒体设备约束
const constraints = {
  video: true,
  audio: false,
};

// 定义全局变量
let mediaRecorder;
let recordedChunks = [];

export default {
  // name: 'HomeView',
  data() {
    return {
      info: {},
      is_video: false,
      video_view: "",
    };
  },
  components: {},
  mounted() {
    if (this.$route.query.id) {
      this.handleGetNews(this.$route.query.id);
    }
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    handleGoBack() {
      history.back(-1);
    },
    async handleGetNews(id) {
      const t = new FormData();
      t.append("id", id);
      // t.append('area', localStorage.getItem('region'))
      const res = await getArticle(t);
      if (res.code === 1) {
        this.info = res.data.value;
      } else {
        alert("请求异常");
      }
    },
    handleStartVideo() {
      const _this = this;
      /////
      // 获取页面元素
      // 获取摄像头流
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.video.srcObject = stream;
          // this.video_view = stream
          // return
          mediaRecorder = new MediaRecorder(stream);
          console.log(mediaRecorder);

          recordedChunks = [];
          mediaRecorder.start();

          // 当开始录制时，将数据存储在recordedChunks数组中
          mediaRecorder.ondataavailable = (event) => {
            recordedChunks.push(event.data);
            _this.is_video = true;
            console.log("录制中");
          };

          // 当录制完成时，将录制视频呈现在video元素中
          mediaRecorder.onstop = () => {
            _this.is_video = false;
            const recordedBlob = new Blob(recordedChunks, {
              type: "video/mp4",
            });
            _this.video_view = URL.createObjectURL(recordedBlob);
            console.log(_this.video_view, "video_view录制完成", recordedBlob);

            let fileobj = new File(
              [recordedBlob],
              `video_${new Date().getTime()}.mp4`,
              {
                type: "video/mp4",
              }
            );

            console.log(fileobj);

            const formData = new FormData();
            formData.append("file", fileobj);

            // console.log(formData)
            // video1.src = URL.createObjectURL(recordedBlob)
            // video1.controls = true
            ///// 上传
            // let fileobj = new File([recordedBlob], name, {
            //   type: 'video/mp4',
            // })

            // // 下载视频
            // var link = document.createElement('a');
            // link.href = URL.createObjectURL(new Blob(recordedChunks, { type: "video/mp4" })); //这里可以修改格式 比如我修改为mp4
            // link.download = `视频-${new Date().getTime()}.mp4`; // 设置下载文件的文件名
            // link.click();

            // let formData = new FormData() //创建form对象
            // formData.append('file', fileobj) //通过append向form对象添加数据
            // http://127.0.0.1:9554/api/sx/test
            fetch(baseAPIURL + "/api/common/upload", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
          };
        })
        .catch((err) => {
          console.error(`摄像头流获取失败: ${err}`);
          alert("获取摄像头失败，请检查摄像头链接是否正常");
        });
      //////
    },
    handleEndVideo() {
      console.log("停止录制", mediaRecorder);
      if (!mediaRecorder) {
        this.is_video = false;
        return;
      }
      if (mediaRecorder.state != "inactive") {
        mediaRecorder.stop();
        this.is_video = false;
      }
      this.$refs.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop(); // 关闭摄像头
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.6rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: rgb(34, 244, 247);
      }

      & > .base_title_image {
        // margin-top: 1.5rem;
        width: 100%;
      }

      & > .base_back_image {
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 0.3rem;
        object-fit: contain;
        position: absolute;
        top: 0.5rem;
        left: 0.2rem;
        z-index: 10;
      }
    }

    & > .video_content {
      display: flex;
      justify-content: space-around;
      // padding: 0 0.3rem;
      margin-bottom: 15px;

      & > video {
        width: 80%;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 5px;
      }

      & > img {
        width: 100%;
      }
    }

    & > .card_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // margin-top: 0.3rem;
      padding: 0.3rem;

      & > .card_item {
        position: relative;
        flex: 1;
        width: calc((100% - 10px) / 2);
        min-width: calc((100% - 10px) / 2);
        max-width: calc((100% - 10px) / 2);
        // height: 100px;
        overflow: hidden;
        line-height: 1;
        margin-bottom: 10px;
        padding: 0.2rem;
        box-sizing: border-box;
        background-size: 100% 100% !important;
        background: url("../static/images/study_item_base.png") no-repeat;

        & > img {
          width: 100%;
        }

        & > .video_base_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.2rem;
          color: rgb(34, 244, 247);
        }
      }
    }
  }
}

.video_transcribe {
  display: flex;
  justify-content: space-around;
  align-content: center;

  & > div {
    font-size: 0.3rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid #fff;
    background-color: #e91521;
    border-radius: .1rem;
  }
  >.tzClass {
    color: #e91521;
    background-color: #fff;
  }
}

.push_video {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  bottom: 0.3rem;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  font-size: 0.3rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid #fff;
}
</style>
