<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <!-- <div class="title">{{ test_name }}</div> -->
        <div class="title">答 题</div>
      </div>
      <img
        src="../static/fh.png"
        alt=""
        class="back_image"
        @click="handleBack"
      />
      <div class="img_code">
        <div style="flex: 1"></div>
        <div style="flex: 3" class="listLeng">共{{ list.length }}题</div>
        <div style="flex: 1" class="menu_search">
          <img
            src="../static/images/code.png"
            alt=""
            @click="is_code_open = true"
          />
        </div>
      </div>

      <!-- 小Tab -->
      <!-- <div class="search_cotnent">
        <img
          src="../static/images/back_base.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search">
          <img
            src="../static/images/code.png"
            alt=""
            @click="is_code_open = true"
          />
        </div>
      </div> -->
      <!-- 内容列表 -->
      <div v-if="!is_over">
        <div class="content_list" v-if="list.length > 0">
          <div class="item_content">
            <div class="sub_content">
              <div class="question_type">
                <span>
                  {{
                    list[index].qustion.type === 'multiple'
                      ? '多选题'
                      : '单选题'
                  }}
                </span>
                <!-- v-if="list[index].qustion.type === 'multiple'"
                <span v-else>单选题 {{ index + 1 }} / {{ list.length }}</span> -->
              </div>
              <span>{{ index + 1 }}. </span>
              <span v-html="list[index].qustion.content"></span>
            </div>
          </div>

          <div class="item_content_select">
            <div class="select_list">
              <div
                :style="{
                  color:
                    list[index].qustion.select_user.indexOf('A') > -1
                      ? '#fff'
                      : '#000',
                  background:
                    list[index].qustion.select_user.indexOf('A') > -1
                      ? '#f04b2b'
                      : '',
                }"
                @click="hanleSelectOption('A')"
              >
                A. {{ list[index].qustion.A }}
              </div>
              <div
                :style="{
                  color:
                    list[index].qustion.select_user.indexOf('B') > -1
                      ? '#fff'
                      : '#000',
                  background:
                    list[index].qustion.select_user.indexOf('B') > -1
                      ? '#f04b2b'
                      : '',
                }"
                v-if="list[index].qustion.B"
                @click="hanleSelectOption('B')"
              >
                B. {{ list[index].qustion.B }}
              </div>
              <div
                :style="{
                  color:
                    list[index].qustion.select_user.indexOf('C') > -1
                      ? '#fff'
                      : '#000',
                  background:
                    list[index].qustion.select_user.indexOf('C') > -1
                      ? '#f04b2b'
                      : '',
                }"
                v-if="list[index].qustion.C"
                @click="hanleSelectOption('C')"
              >
                C. {{ list[index].qustion.C }}
              </div>
              <div
                :style="{
                  color:
                    list[index].qustion.select_user.indexOf('D') > -1
                      ? '#fff'
                      : '#000',
                  background:
                    list[index].qustion.select_user.indexOf('D') > -1
                      ? '#f04b2b'
                      : '',
                }"
                v-if="list[index].qustion.D"
                @click="hanleSelectOption('D')"
              >
                D. {{ list[index].qustion.D }}
              </div>
            </div>
          </div>
        </div>
        <div class="content_list laoding_d" v-else>数据加载中</div>
      </div>
      <div class="achievement" v-else-if="type === 'show'">
        <div style="color: #fff">回答正确: {{ over_info.correct }}题</div>
        <div style="color: #ff0000">回答错误: {{ over_info.error }}题</div>
        <div class="over_btn" @click="handleRestTest">
          <div>重新答题</div>
        </div>
      </div>
      <div class="achievement" v-else>
        <div style="color: #fff; font-size: 0.8rem; margin-bottom: 0.2rem">
          得分: {{ score }}
        </div>
        <div style="font-size: 0.4rem">用时: {{ time_test }}</div>
        <div class="over_btn" @click="handleRestTest">
          <div>重新答题</div>
        </div>
      </div>
      <div class="laoding_d" v-if="loading">数据提交中</div>
      <div class="code_qr" v-if="is_code_open" @click="is_code_open = false">
        <img :src="codeqr" alt="" />
      </div>

      <div class="item_content_btn">
        <div class="select_list">
          <div @click="handleSubTest">提交</div>
          <div @click="handleBackQuestion" style="font-weight: bold">
            上一题
          </div>
          <div @click="handleAddQuestion" class="xiaClass">下一题</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleGetTest, sendTest } from '../api/index'
import QRCode from 'qrcode'
export default {
  // name: 'HomeView',
  data() {
    return {
      uid: '',
      is_source: '',
      code: '',
      id: 0,
      type: '',
      test_key: '',
      //////////////
      list: [],
      index: 0,
      ////////
      test_name: '',
      ///////
      is_over: false,
      /////
      over_info: {
        correct: 0,
        error: 0,
      },
      ////
      score: 0,
      time_test: 0,
      ////
      loading: false,
      codeqr: '',
      is_code_open: false,
      //////////
    }
  },
  components: {},
  mounted() {
    if (this.$route.query.is_source) {
      this.is_source = this.$route.query.is_source
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid
    }
    if (this.$route.query.code) {
      this.code = this.$route.query.code
      this.handleGetTest(this.$route.query.code)
      this.getQRCodeUrl()
    }
  },
  computed: {},
  methods: {
    getQRCodeUrl() {
      QRCode.toDataURL(
        location.origin +
          location.pathname +
          `/#/login?id=${this.id}&code=${this.code || ''}&uid=${
            this.uid
          }&is_source=${this.is_source}`,
        {
          color: {
            dark: '#000',
            light: '#fff',
          },
        }
      )
        .then((url) => {
          // 获取到url后即可在页面使用--二维码图片
          console.log('url', url)
          this.codeqr = url
        })
        .catch((e) => {
          console.log('e', e)
        })
    },
    hanleSelectOption(option) {
      const currInfo = this.list[this.index].qustion
      console.log(currInfo.type, option)

      const currAswer = this.list[this.index].qustion.select_user || ''

      if (currInfo.type === 'multiple') {
        let d = currAswer.split(',').filter((v) => !!v)
        if (d.indexOf(option) > -1) {
          // 删除
          d = d.filter((v) => v !== option)
        } else {
          d.push(option)
        }
        this.list[this.index].qustion.select_user = d.join(',')
      }
      if (currInfo.type === 'only') {
        this.list[this.index].qustion.select_user = option
      }
    },
    handleBack() {
      history.back(-1)
    },
    async handleGetTest(code) {
      const t = new FormData()
      t.append('code', code)
      // t.append("token", token)
      const res = await handleGetTest(t)
      if (res.code === 1) {
        this.test_name = res.data.test.name
        this.type = res.data.test.type
        this.test_key = res.data.key
        this.list = res.data.list.map((v, i) => {
          // 题型;only=单选,multiple=多选input=简答,open=开放
          // type 'test','study','show'
          // test= 考核  study=学习  show=查阅
          return {
            ...v,
            qustion: {
              ...v.qustion,
              select_user: '',
              index: i + 1,
            },
          }
        })
      } else {
        alert(res.msg)
        history.back()
      }
    },
    handleBackQuestion() {
      if (this.index === 0) return
      this.index = this.index - 1
    },
    handleAddQuestion() {
      if (!this.list[this.index].qustion.select_user) {
        alert('请先选择选项')
        return
      }
      if (this.index + 1 === this.list.length) {
        alert('请提交卷')
        return
      }
      this.index = this.index + 1
    },
    async handleSubTest() {
      // 检查用户是否选择完毕
      const d = this.list.filter((v) => !v.qustion.select_user)
      if (d.length > 0) {
        alert('请选择完所有题目')
        return
      }
      /////// 自己判断
      if (this.type === 'show') {
        // 单选对比
        // 回答正确
        const correct = this.list
          .filter((v) => v.qustion.type === 'only')
          .filter((v) => v.qustion.right === v.qustion.select_user)
        const error = this.list
          .filter((v) => v.qustion.type === 'only')
          .filter((v) => v.qustion.right !== v.qustion.select_user)
        this.over_info.correct = correct.length
        this.over_info.error = error.length
        this.is_over = true
        // 多选
        return
      }
      // sendTest
      const list = this.list.map((v) => {
        return {
          ...v,
          qustion: {
            ...v.qustion,
            input: v.qustion.select_user,
          },
        }
      })
      const f = new FormData()
      f.append('uid', this.uid)
      f.append('test_id', this.id)
      f.append('key', this.test_key)
      f.append('json', encodeURIComponent(JSON.stringify(list)))
      f.append('token', localStorage.getItem('token'))
      f.append('username', localStorage.getItem('username'))
      f.append('work_area', localStorage.getItem('work_area'))
      f.append('job_type', localStorage.getItem('job_type'))
      this.loading = true
      const res = await sendTest(f)
      this.loading = false
      if (res.code === 1) {
        this.score = res.data.tlog.score
        this.time_test = res.data.tlog.keeptime
        this.is_over = true
      } else {
        alert(res.msg)
      }
    },
    handleRestTest() {
      this.handleGetTest(this.code)
      this.is_over = false
    },
  },
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
        overflow: hidden;
        width: 82%;
        margin: 0 auto;
        left: 0;
        right: 0;
        word-spacing: normal;
        text-overflow: ellipsis;
        // height: 0.8rem;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .back_image {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }

      & > .menu_search {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;
        margin-right: 1.3rem;

        & > img {
          width: 0.6rem;
          height: 0.6rem;
        }

        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          margin-right: 0.2rem;
        }

        & > .search_text {
          position: absolute;
          left: 0.2rem;
          font-size: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;

      & > .menu_top_left {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .video_content {
      display: flex;
      justify-content: center;
      // align-items: center;
      padding: 0.3rem;

      & > img {
        width: 65%;
      }

      & > .video_desc {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 35%;
        color: #000;
        padding: 0.3rem;
        font-size: 0.3rem;
        background: rgb(10, 219, 252);

        & .title {
          font-weight: bold;
          font-size: 0.4rem;
        }

        & .tag_hot {
          font-size: 0.2rem;
        }
      }
    }

    & .content_list {
      width: 90%;
      margin: 0.5rem auto;
      box-sizing: border-box;
      padding: 0.3rem;
      background-color: #fff;
      border-radius: 0.8rem;

      & > .item_content {
        position: relative;
        z-index: 1;
        color: #333;
        font-weight: bold;
        // padding: 0.3rem 1rem;

        & > span {
          font-size: 0.3rem;
        }

        & > .sub_content {
          position: relative;
          // display: flex;
          //   align-items: center;
          font-size: 0.4rem;
          padding: 0.3rem;
          // height: 4rem;
          background-size: 100% 100% !important;
          // background: url('../static/images/study_item_base.png') no-repeat;
          & > .question_type {
            // position: absolute;
            // top: 0.2rem;
            // left: 0.5rem;
            width: 2.8rem;
            color: #fff;
            font-size: 0.4rem;
            padding: 0.14rem 0.3rem;
            text-align: center;
            border-radius: 0.15rem;
            box-sizing: border-box;
            background-color: #e91c1d;
            letter-spacing: 0.05rem;
            margin-bottom: 0.3rem;
          }
        }
      }

      & > .item_content_select {
        position: relative;
        z-index: 0;
        // padding: 0.3rem 1rem;

        & > .select_list {
          font-size: 0.3rem;
          font-weight: bold;
          padding: 0.3rem;
          // height: 4.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-size: 100% 100% !important;
          // background: url('../static/images/study_item_base.png') no-repeat;

          & > div {
            color: #000;
            padding: 0.3rem;
            margin: 0.3rem 0;
            border-radius: 0.3rem;
            background: #eeeced;
            font-weight: 500;
            font-size: 0.38rem;
          }
        }
      }

      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.4rem 0.3rem 0.3rem 0.3rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/news_base.png') no-repeat;

        & > .item_images {
          width: 3rem;
          // height: 3rem;
          margin-right: 0.2rem;

          & > img {
            width: 100%;
            border-radius: 3px;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: rgb(34, 244, 247);

          & > .app_name {
            font-size: 0.4rem;
            //   margin-bottom: 0.1rem;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            margin: 0.2rem 0;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;

            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.5rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}
.item_content_btn {
  position: relative;
  z-index: 0;
  padding: 0.1rem 0.2rem;
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  border-radius: 0.3rem;
  box-sizing: border-box;

  & > .select_list {
    font-size: 0.3rem;
    padding: 0.1rem 0.3rem;
    // height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: 100% 100% !important;
    // background: url("../static/images/study_item_base.png") no-repeat;

    & > div {
      text-align: center;
      width: 2rem;
      color: #434343;
      font-size: 0.38rem;
      padding: 0.17rem 0.25rem;
      box-sizing: border-box;
      margin: 0.3rem 0;
      border-radius: 0.5rem;
      background: #eeeced;
      letter-spacing: 0.1rem;
    }
    > .xiaClass {
      width: 3rem;
      background-color: #e9121b;
      font-weight: bold;
      color: #fff;
      font-size: 0.45rem;
    }
  }
}
.achievement {
  text-align: center;
  font-size: 0.5rem;
  margin-top: 1.5rem;
  & > .over_btn {
    padding: 0 1.5rem;
    overflow: hidden;
    margin-top: 2.5rem;
    & > div {
      padding: 0.2rem 0;
      font-size: 0.5rem;
      margin: 0.4rem 0;
      border-radius: 4px;
      background: #ae3636;
    }
  }
}

.laoding_d {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.8;
  text-align: center;
  padding-top: 5rem;
  font-size: 0.5rem;
}

.code_qr {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.8;
  text-align: center;
  padding-top: 5rem;
  font-size: 0.5rem;
  & > img {
    width: 80%;
  }
}
.back_image {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.3rem;
  object-fit: contain;
  position: absolute;
  top: 0.8rem;
  left: 0.3rem;
}
.img_code {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  > .menu_search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.listLeng {
  width: 5.5rem;
  height: 1.2rem;
  background-color: #fff;
  color: #e91c1d;
  font-size: 0.6rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2rem;
  border-radius: 0.8rem;
}
</style>
