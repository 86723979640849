<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/images/base_images.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">课件名称</div>
        <img src="../static/images/study_bvase.png" alt="" />
      </div>
      <!-- 小Tab -->
      <div class="search_cotnent">
        <img
          src="../static/images/back_base.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search"></div>
      </div>
      <!-- 内容列表 -->
      <div class="content_list" ref="tablescroll">
        <!-- 样式1 -->
        <div class="title_content">
          <div v-html="content"></div>
          <canvas
            id="pdf-canvas"
            style="width: 100%; margin-top: 10px"
          ></canvas>
          <div class="btn_page" v-if="pdf_url">
            <button @click="handleTopPage">上一页</button>
            <button @click="handleNextPage">下一页</button>
          </div>
        </div>
      </div>
      <div class="loading" v-if="loading">加载中</div>
    </div>
    <a class="back_top" @click.prevent="handleBackTop">Top</a>
  </div>
</template>
<script>
import { handleGetArticle, GetPdf } from '../api/index'
import { baseURL } from '../request/index'
import PDF from '../components/PDF.vue'
export default {
  // name: 'HomeView',
  data() {
    return {
      info: {},
      content: '',
      pdf_url: '',
      page: 1,
      loading: false,
      pdf: null,
      numPages: 1,
    }
  },
  components: {
    PDF,
  },
  mounted() {
    // https://view.officeapps.live.com/op/view.aspx?src=
    if (this.$route.query.id) {
      this.handleGetInfo(this.$route.query.id)
    }
  },
  watch: {
    loading: function (e) {
      if (!e) {
        const _this = this
        setTimeout(() => {
          _this.handleNextPage()
        }, 500)
        setTimeout(() => {
          _this.handleTopPage()
        }, 700)
      }
    },
  },
  methods: {
    handleBack() {
      history.back(-1)
    },
    async handleGetInfo(id) {
      const t = new FormData()
      t.append('id', id)
      // t.append('area', localStorage.getItem('region'))
      const res = await handleGetArticle(t)
      // this.info = res.data.value
      if (res.data.value.content) {
        this.content = res.data.value.content
      }
      if (res.data.value.view_file) {
        // "https://view.officeapps.live.com/op/view.aspx?src=" +
        this.pdf_url =
          baseURL +
          `/api/index/getpdf?path=${baseURL + res.data.value.view_file}`
        this.handlleToPage()
      }
    },
    handlleToPage() {
      const _this = this
      // 获取 canvas 元素
      const canvas = document.getElementById('pdf-canvas')
      this.loading = true
      // 用 pdf.js 渲染 PDF 文档
      pdfjsLib.getDocument(this.pdf_url).promise.then(function (pdfDoc) {
        _this.pdf = pdfDoc
        // 获取PDF文件的页数
        _this.numPages = pdfDoc.numPages
        pdfDoc.getPage(_this.page).then(function (page) {
          const viewport = page.getViewport({ scale: 0 })
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width

          page.render({
            canvasContext: context,
            viewport: viewport,
          })
          _this.loading = false
        })
      })
    },
    handleTopPage() {
      if (this.page <= 1) {
        return
      }
      this.page = this.page - 1
      this.renderPage(this.page)
    },
    handleNextPage() {
      if (this.page >= this.numPages) {
        return
      }
      this.page = this.page + 1
      this.renderPage(this.page)
    },
    renderPage(num) {
      const canvas = document.getElementById('pdf-canvas')
      this.pdf.getPage(num).then(function (page) {
        const viewport = page.getViewport({ scale: 1 })
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width

        page.render({
          canvasContext: context,
          viewport: viewport,
        })
      })
    },
    handleBackTop() {
      this.$nextTick(() => {
        this.$refs.tablescroll.scrollTop = 0
      })
    },
  },
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.6rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: rgb(34, 244, 247);
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .back_image {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }

      & > .menu_search {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;

        & > img {
          width: 1rem;
          height: 1rem;
        }

        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          margin-right: 0.2rem;
        }

        & > .search_text {
          position: absolute;
          left: 0.2rem;
          font-size: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;

      & > .menu_top_left {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;
      height: 100vh;
      overflow-y: scroll;

      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.2rem;
        background-size: 100% 100% !important;
        background: url('../static/images/href_right_base.png') no-repeat;

        & > .item_images {
          width: 3rem;
          height: 3rem;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: rgb(34, 244, 247);

          & > .app_name {
            font-size: 0.4rem;
            padding-bottom: 0.2rem;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;

            & > span {
              font-size: 0.2rem;
              margin-right: 0.8rem;
            }
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.5rem;
            }
          }

          & > .test_type {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 0.2rem;
            color: #000;
            padding: 0.1rem;
            border-radius: 2px;
            background: rgb(34, 244, 247);
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        // height: 2rem;
        // line-height: 2rem;
        text-align: center;
        font-size: 0.3rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        // background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.8;
  text-align: center;
  padding-top: 6rem;
}

.btn_page {
  margin-top: 0.3rem;
  & > button {
    margin: 0 0.2rem;
    border: none;
    border-radius: 4px;
    padding: 0.2rem 0.5rem;
  }
}

.back_top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.3rem;
  background: #2ca4ff;
  border-radius: 50%;
  font-size: 0.3rem;
  padding: 0.2rem;
  z-index: 111;
  color: #fff;
}
</style>
