<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">VR交互学习站</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <!-- 小Tab -->
      <div class="search_cotnent">
        <img
          src="../static/fh.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <!-- <img src="../static/images/left.png" alt="" class="left_image" /> -->
        <!-- <div class="menu_search"></div> -->
      </div>
      <!-- <div class="menu_top_content">
          <div class="menu_top_left" @click="curr_index = 0">
            <div class="img_top">
              <img src="../static/images/left.png" alt="" srcset="" />
            </div>
            <div class="img_footer">
              <img src="../static/images/left_base.png" alt="" />
              <div>{{ left }}</div>
            </div>
          </div>
          <div class="menu_top_right"  @click="curr_index = 1">
            <div class="img_top">
              <img src="../static/images/left.png" alt="" srcset="" />
            </div>
            <div class="img_footer">
              <img src="../static/images/right_base.png" alt="" />
              <div>{{ right }}</div>
            </div>
          </div>
        </div> -->
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="item_images">
            <img :src="handlleGetUrl(item.poster_image)" alt="" />
          </div>
          <div class="item_content">
            <div class="app_name">{{ item.name }}</div>
            <div class="app_desc">
              {{ item.description }}
            </div>
            <div class="app_size">使用次数: {{ item.count }}</div>
            <div class="app_open" @click="handleOpen(item)">
              <div>
                <img src="@/assets/bf.png" alt="" />
              </div>
              <div class="open_text">打开</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { baseURL } from '../request/index'
import { handleGetArticleList } from '../api/index'
export default {
  // name: 'HomeView',
  data() {
    return {
      list: [],
      isReturn: false,
    }
  },
  components: {},
  mounted() {
    if (this.$route.query.id) {
      this.handleGetList(this.$route.query.id)
    }
  },
  computed: {
    handlleGetUrl() {
      return function (url) {
        return baseURL + url
      }
    },
  },
  methods: {
    handleBack() {
      history.back(-1)
    },
    async handleGetList(sortId) {
      const t = new FormData()
      t.append('type', 'vr')
      t.append('sortId', sortId)
      const res = await handleGetArticleList(t)
      this.list = res.data.list
    },
    handleOpen(item) {
      const _this = this
      if (this.isReturn) {
        return
      }
      if (item.outlink) {
        this.isReturn = true
        // window.open(item.outlink)
        location.href = item.outlink
        setTimeout(() => {
          _this.isReturn = false
        }, 2000)
        return
      }
      if (item.tolink) {
        // window.open(item.tolink)
        this.isReturn = true
        location.href = item.tolink
        setTimeout(() => {
          _this.isReturn = false
        }, 2000)
        return
      }
      this.$router.push(`${item.tolink}?id=${item.id}`)
      // this.$router.push(`/courseware?id=` + item.id)
    },
  },
}
</script>
<style scoped lang="scss">
.search_cotnent {
  padding: 0 0.5rem;
  & > img {
    width: 1rem;
  }
}

.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.6rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;

      & > .menu_top_left {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;

      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        margin-bottom: 0.3rem;
        padding: 0.2rem;
        background-size: 100% 100% !important;
        background-color: #fff;
        border-radius: .3rem;
        // background: url('../static/images/href_right_base.png') no-repeat;
        height: 2rem;
        overflow: hidden;
        & > .item_images {
          width: 3rem;
          height: 2rem;
          overflow: hidden;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: #333;

          & > .app_name {
            font-size: 0.4rem;
            font-weight: bold;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.5rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}
.back_image {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.3rem;
  object-fit: contain;
  position: absolute;
  top: 0.7rem;
  left: 0.3rem;
}
</style>
