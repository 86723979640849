import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StudyView from '../views/Study.vue'
import ContactView from '../views/Contact.vue'
import CoursewareLearningView from '../views/CoursewareLearning.vue'
import VideoView from '../views/Video.vue'
import AnswerView from '../views/Answer.vue'
import CoursewareView from '../views/Courseware.vue'
import Learning from '../views/Learning.vue'
import NewsView from '../views/News'
import InteractionView from '../views/Interaction.vue'
import MeetingView from '../views/Meeting'
import TestView from '../views/Test'
import StudyInfoView from '../views/Study_info'
import SelectTestView from '../views/SelectTest'
import SecureView from '../views/Secure.vue'
import LoginView from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/study',
    name: 'study',
    component: StudyView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/courseware_Learning',
    name: 'coursewareLearningView',
    component: CoursewareLearningView,
  },
  {
    path: '/video',
    name: 'video',
    component: VideoView,
  },
  {
    path: '/nswer',
    name: 'nswer',
    component: AnswerView,
  },
  {
    path: '/courseware',
    name: 'courseware',
    component: CoursewareView,
  },
  {
    path: '/Learning',
    name: 'Learning',
    component: Learning,
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView,
  },
  {
    path: '/interaction',
    name: 'interaction',
    component: InteractionView,
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: MeetingView,
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
  },
  {
    path: '/study_info',
    name: 'study_info',
    component: StudyInfoView,
  },
  {
    path: '/select_test',
    name: 'select_test',
    component: SelectTestView,
  },
  {
    path: '/secure',
    name: 'secure',
    component: SecureView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
]

const router = new VueRouter({
  routes,
})

export default router
