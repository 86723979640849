<template>
  <div class="content_index">
    <img
      class="shezImg"
      src="@/static/set.png"
      alt=""
      @click="is_set_show = true"
    />
    <img class="vrImg" src="@/static/pok.png" @click="handleClickPioc" alt="" />
    <!-- 设置弹窗 -->
    <diV class="set_shop_moadel" v-if="is_set_show">
      <div class="set_title">设置</div>
      <div class="row_info">
        <div>Pico链接</div>
        <input
          type="text"
          class="input"
          placeholder="请输入"
          v-model="pico_url"
        />
      </div>
      <div class="row_info">
        <div>设置区域</div>
        <input
          type="text"
          class="input"
          placeholder="请输入"
          v-model="region"
        />
      </div>
      <div class="row_info">
        <div>设置KEY</div>
        <input type="text" class="input" placeholder="请输入" v-model="key" />
      </div>
      <div class="isOkbtn" @click="handleset">确 定</div>
    </diV>
    <!-- 遮罩 -->
    <div class="more_div" @click="is_set_show = false" v-if="is_set_show"></div>
    <!-- loading -->
    <div class="loading_more" v-if="isLoading">数据加载中</div>
    <!-- 背景块 -->
    <div class="warp_top_bg"></div>
    <!-- 内容 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="title_warp">
        <h3>产业工人培训站</h3>
      </div>
      <!-- 滚动 -->
      <div class="roll_content">
        <img src="../static/lb.png" alt="" />
        <span>|</span>
        <div class="box">
          <div class="roll_text">【辅培养护工人，淬炼蓝领精英】</div>
        </div>
      </div>
      <!-- 轮播 -->
      <div class="swiper">
        <Banner></Banner>
      </div>
      <!-- tab -->
      <div class="tab">
        <div
          class="son"
          v-for="(item, index) in menu_list"
          :key="index"
          @click="handleGoto(item.key)"
        >
          <div v-if="item.key == 'vr'" class="icon"></div>
          <div v-if="item.key == 'video'" class="icon icon2"></div>
          <div v-if="item.key == 'aps'" class="icon icon3"></div>
          <div v-if="item.key == 'exam'" class="icon icon4"></div>
          <div v-if="item.key == 'safe'" class="icon icon5"></div>
          <div v-if="item.key == 'news'" class="icon icon6"></div>
          <div class="text">{{ item.value }}</div>
        </div>
        <!-- <div class="son" @click="handleGoto('vr')">
          <div class="icon"></div>
          <div class="text">VR实训</div>
        </div>
        <div class="son" @click="handleGoto('video')">
          <div class="icon icon2"></div>
          <div class="text">视频课件</div>
        </div>
        <div class="son" @click="handleGoto('aps')">
          <div class="icon icon3"></div>
          <div class="text">互动培训</div>
        </div>
        <div class="son" @click="handleGoto('exam')">
          <div class="icon icon4"></div>
          <div class="text">知识考核</div>
        </div>
        <div class="son" @click="handleGoto('safe')">
          <div class="icon icon5"></div>
          <div class="text">班前会议</div>
        </div>
        <div class="son" @click="handleGoto('news')">
          <div class="icon icon6"></div>
          <div class="text">行业新闻</div>
        </div> -->
      </div>
      <!-- 精选 热点 -->
      <div class="jx">
        <div class="son" style="margin-bottom: 0.2rem">
          <div class="top">
            <div class="l"></div>
            <div class="text">精选推荐</div>
          </div>
          <div class="imgs">
            <div
              class="img"
              @click="handleGoToInfo(item)"
              v-for="(item, index) in FlagList"
              :key="index"
            >
              <img :src="item.video_cove" alt="" />
            </div>
          </div>
        </div>
        <div class="son">
          <div class="top">
            <div class="l"></div>
            <div class="text">热点新闻</div>
          </div>
          <div class="imgs">
            <div
              class="img"
              @click="handleGoToInfo(item)"
              v-for="(item, index) in FlagList_hot"
              :key="index"
            >
              <img :src="item.video_cove" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="dibu">
        <div class="l">
          <img :src="logo" alt="" />
        </div>
        <div class="r">
          <div
            class="data"
            @click="handleGoToFooter(item)"
            v-for="(item, index) in FlagList_home"
            :key="index"
          >
            <img :src="handlleGetUrl(item.poster_image)" alt="" />
            <div class="text slh">{{ item.name }}</div>
            <div class="info">
              <div class="tex">阅读次数:{{ item.reading_time }}</div>
              <div class="tex">发布日期: {{ item.create_time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { baseURL } from '../request/index'
import { handleIndexSortType, handleIndexFlagList } from '../api/index'
import Banner from '../components/Banner.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      timer: null,
      menu_list: [],
      FlagList: [],
      FlagList_hot: [],
      FlagList_home: [],
      logo: '',
      isLoading: false,
      is_set_show: false,
      pico_url: '',
      region: '',
      key: '',
    }
  },
  components: {
    Banner,
  },
  watch: {
    pico_url: function (e) {
      localStorage.setItem('pico_url', e)
    },
    region: function (e) {
      localStorage.setItem('region', e)
    },
    key: function (e) {
      localStorage.setItem('key', e)
    },
  },
  mounted() {
    this.isLoading = true
    const _this = this
    // this.handleIndexSort()
    // this.handleIndexFlag()
    // this.handleIndexFlagHot()
    // this.handleIndexFlagHome()
    _this.timer = setInterval(() => {
      _this.logo = _this.$store.state.web_config.web_logo
      if (_this.$store.state.web_config.web_logo) {
        clearInterval(_this.timer)
      }
    }, 300)

    // 回显设置数据
    this.pico_url = localStorage.getItem('pico_url') || ''
    this.region = localStorage.getItem('region') || ''
    this.key = localStorage.getItem('key') || ''

    // 创建异步请求的Promise对象数组
    const promises = [
      this.handleIndexSort(),
      this.handleIndexFlag(),
      this.handleIndexFlagHot(),
      this.handleIndexFlagHome(),
    ]

    // 使用Promise.all等待所有异步请求完成
    Promise.all(promises)
      .then((responses) => {
        _this.isLoading = false
      })
      .catch((error) => {
        _this.isLoading = false
      })
  },
  computed: {
    handlleGetUrl() {
      return function (url) {
        return baseURL + url
      }
    },
  },
  methods: {
    handleset() {
      location.reload()
    },
    handleClickPioc() {
      const pico_url = localStorage.getItem('pico_url')
      if (pico_url) {
        window.open(pico_url)
      }
    },
    handleGoToInfo(item) {
      if (item.tolink) {
        window.open(item.tolink)
        return
      }
      if (item.outlink) {
        window.open(item.outlink)
        return
      }
      this.$router.push(`/video?id=${item.id}&type=video`)
    },
    handleGoToFooter(item) {
      if (item.tolink) {
        // location.href = item.tolink
        window.open(item.tolink)
        return
      }
      if (item.outlink) {
        // location.href = item.tolink
        window.open(item.outlink)
        return
      }
      // this.$router.push(`/courseware?id=${item.id}`)
    },
    handleGoto(key) {
      // console.log(item)
      if (key === 'video') {
        this.$router.push('/courseware_Learning')
      }
      if (key === 'vr') {
        this.$router.push('/study')
      }
      if (key === 'news') {
        this.$router.push('/news')
      }
      if (key === 'aps') {
        this.$router.push('/interaction')
      }
      if (key === 'exam') {
        this.$router.push('/contact?is_source=home')
      }
      if (key === 'safe') {
        this.$router.push('/secure')
      }
    },
    async handleIndexSort() {
      const res = await handleIndexSortType()
      this.menu_list = res.data.list
    },
    async handleIndexFlag() {
      const d = new FormData()
      d.append('flag', 'recommend')
      d.append('count', '3')
      d.append('page', '1')
      const res = await handleIndexFlagList(d)
      console.log(res, 'ressss')
      this.FlagList = res.list.map((v) => {
        return {
          id: v.id,
          video_name: v.name,
          video_cove: baseURL + v.poster_image,
          tolink: v.tolink,
          outlink: v.outlink,
        }
      })
    },
    async handleIndexFlagHot() {
      const d = new FormData()
      d.append('flag', 'hot')
      d.append('count', '3')
      d.append('page', '1')
      const res = await handleIndexFlagList(d)
      this.FlagList_hot = res.list.map((v) => {
        return {
          id: v.id,
          video_name: v.name,
          video_cove: baseURL + v.poster_image,
          tolink: v.tolink,
          outlink: v.outlink,
        }
      })
    },
    async handleIndexFlagHome() {
      const d = new FormData()
      d.append('flag', 'home')
      d.append('count', '3')
      d.append('page', '1')
      const res = await handleIndexFlagList(d)
      this.FlagList_home = res.list.map((v) => {
        return {
          ...v,
          id: v.id,
          create_time: dayjs(v.createtime * 1000).format('YYYY-MM-DD'),
          reading_time: v.count,
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// 新UI
.loading_more {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: linear-gradient(to right, #e80c16, #f16f15);
  font-size: 0.3rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding-top: 40vh;
}

.warp_top_bg {
  width: 100%;
  height: 5rem;
  border-radius: 0 0 50% 50%;
  background: linear-gradient(to right, #e80c16, #f16f15);
}

.content_body {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  & > .title_warp {
    color: #fff;
    font-size: 0.5rem;
    font-weight: bold;
    padding: 0.3rem 0;
    text-align: center;
    & > h3 {
      text-shadow: 2px 2px 1px #fc915f;
    }
  }
  & > .roll_content {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    width: 90%;
    padding: 0.1rem 0.4rem;
    border-radius: 25px;
    margin: 0 auto;
    background: #ecd7d6;

    & > img {
      width: 0.4rem;
      line-height: 1;
    }
    & > span {
      color: #5d5d5d;
      font-size: 0.3rem;
      padding: 0 0.3rem;
      line-height: 1;
    }
    > .box {
      width: 8rem;
      overflow: hidden;
    }
    .roll_text {
      width: 8rem;
      color: #5d5d5d;
      font-size: 0.3rem;
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
      box-sizing: border-box;
      white-space: nowrap;
      animation: scroll 5s linear infinite;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.swiper {
  width: 90%;
  margin: 0.5rem auto;
}

.tab {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  > .son {
    width: 1.5rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .icon {
      width: 1.5rem;
      height: 1.5rem;
      // border-radius: 50%;
      // margin-bottom: .4rem;
      // box-shadow: .05rem .05rem .2rem rgba(0, 0, 0, 0.2);
      background: url('@/static/icon/vr.png') no-repeat;
      background-size: cover;
    }
    > .icon2 {
      background: url('@/static/icon/sp.png') no-repeat;
      background-size: cover;
    }
    > .icon3 {
      background: url('@/static/icon/hd.png') no-repeat;
      background-size: cover;
    }
    > .icon4 {
      background: url('@/static/icon/zs.png') no-repeat;
      background-size: cover;
    }
    > .icon5 {
      background: url('@/static/icon/hy.png') no-repeat;
      background-size: cover;
    }
    > .icon6 {
      background: url('@/static/icon/xw.png') no-repeat;
      background-size: cover;
    }
    > .text {
      color: #5d5f5e;
      font-size: 0.3rem;
      font-weight: 600;
    }
  }
}

.jx {
  width: 90%;
  height: auto;
  margin: 0.2rem auto;
  border-radius: 0.4rem;
  background-color: #f2f2f2;
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  > .son {
    width: 100%;
    > .top {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      > .l {
        width: 0.1rem;
        height: 0.43rem;
        background-color: #e4020f;
        border-radius: 0.4rem;
        margin-right: 0.2rem;
      }
      > .text {
        font-size: 0.32rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
      }
    }
    > .imgs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .img {
        width: 2.7rem;
        height: 1.6rem;
        border-radius: 0.2rem;
        overflow: hidden;
        box-shadow: 0.05rem 0.05rem 0.2rem rgba(0, 0, 0, 0.2);
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.dibu {
  width: 90%;
  height: 3.2rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  background: linear-gradient(to right, #e9121b, #f27116);
  box-shadow: 0rem 0.1rem 0.25rem rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > .l {
    width: 2rem;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  > .r {
    width: calc(100% - 2.3rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > .data {
      width: 100%;
      display: flex;
      align-items: center;
      > img {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.2rem;
        border-radius: 50%;
      }
      > .text {
        width: 3.2rem;
        font-size: 0.28rem;
        font-weight: bold;
        color: #fff;
        margin-right: 0.2rem;
      }
      > .info {
        width: auto;
        > div {
          font-size: 0.15rem;
          color: #fff;
        }
      }
    }
  }
}

.shezImg {
  width: 0.7rem;
  height: 0.7rem;
  object-fit: cover;
  position: absolute;
  top: 0.35rem;
  right: 0.5rem;
  z-index: 99;
}
.vrImg {
  width: 0.7rem;
  object-fit: contain;
  position: absolute;
  top: 0.4rem;
  right: 1.5rem;
  z-index: 99;
}

.set_shop_moadel {
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
  width: 90%;
  // height: 40vh;
  border: 1px solid #b0b0b0;
  border-radius: 0.5rem;
  background: #fff;
  padding-bottom: 1rem;
  & > .set_title {
    font-size: 20px;
    font-weight: bold;
    padding: 0.4rem 0;
    text-align: center;
    // margin-bottom: 1rem;
  }
  & > .row_info {
    width: 85%;
    background-color: #e3e3e3;
    font-size: 0.4rem;
    display: flex;
    padding: 0 0.3rem;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    & > div {
      // line-height: 2;
      color: #ed4d4a;
      font-weight: bold;
      letter-spacing: 0.05rem;
    }
    & > input {
      padding: 0.2rem;
      font-size: 0.3rem;
    }
  }
  & > .isOkbtn {
    width: 60%;
    color: #fff;
    text-align: center;
    font-size: 0.5rem;
    line-height: 2;
    margin: 0 auto;
    border-radius: 0.7rem;
    font-weight: bold;
    background: linear-gradient(to right, #f0413d, #f47538);
  }
}

.more_div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  background: #000;
  z-index: 99;
}
.input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: transparent;
}
</style>
