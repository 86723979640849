import { render, staticRenderFns } from "./CoursewareLearning.vue?vue&type=template&id=3ce7ce40&scoped=true"
import script from "./CoursewareLearning.vue?vue&type=script&lang=js"
export * from "./CoursewareLearning.vue?vue&type=script&lang=js"
import style0 from "./CoursewareLearning.vue?vue&type=style&index=0&id=3ce7ce40&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce7ce40",
  null
  
)

export default component.exports