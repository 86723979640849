<template>
  <div style="width: 100%">
    <!-- 配置slider组件 -->
    <slider
      ref="slider"
      :options="options"
      @slide="slide"
      @tap="onTap"
      @init="onInit"
    >
      <!-- 直接使用slideritem slot -->
      <slideritem
        v-for="(item, index) in someList"
        :key="index"
        :style="item.style"
      >
        <img :src="item.src" alt="" style="width: 100%" />
      </slideritem>
      <!-- 设置loading,可自定义 -->
      <div slot="loading">loading...</div>
    </slider>
  </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'
import { handleIndexBanner } from '../api/index'
import { baseURL } from '../request/index'
export default {
  data() {
    return {
      someList: [],
      options: {
        // currentPage: 0,
        // thresholdDistance: 500,
        // thresholdTime: 100,
        autoplay: 3000,
        loop: true,
        // direction: 'vertical',
        // loopedSlides: 1,
        // slidesToScroll: 1,
        // timingFunction: 'ease',
        // speed: 300
      },
    }
  },
  components: {
    slider,
    slideritem,
  },
  mounted() {
    this.handleInitBanner()
  },
  methods: {
    async handleInitBanner() {
      const res = await handleIndexBanner()
      this.someList = res.data.map((v) => {
        return {
          src: baseURL + v.bgimage,
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.slider-item {
  height: 4rem;
  border-radius: .2rem;
  overflow: hidden;
  border: .02rem solid #fff;
  box-sizing: border-box;
  >img {
    // border-radius: .2rem;
    object-fit: cover;
  }
}

 .slider-container {
  border-radius: .2rem;
  overflow: hidden;
}
::v-deep .slider-pagination-bullets {
  bottom: 0 !important;
}
</style>
