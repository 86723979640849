<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">互 动</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <img
        src="../static/fh.png"
        alt=""
        class="back_image"
        @click="handleBack"
      />
      <!-- 小Tab -->
      <!-- <div class="search_cotnent">
        <img
          src="../static/fh.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search">
          <img src="../static/images/search.png" alt="" />
          <input type="text" placeholder="请输入内容" v-model="search_value" />
        </div>
      </div> -->
      <div class="input">
        <input type="text" placeholder="请输入内容" v-model="search_value" />
      </div>
      <div class="menu_top_content"></div>
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="item_images">
            <!-- <img :src="handleGetUrl(item.poster_image)" alt="" /> -->
            <img :src="baseURL + item.poster_image" alt="" />
          </div>
          <div class="item_content">
            <div class="app_name slh">{{ item.name }}</div>
            <div class="app_desc slh2">
              {{ item.description }}
            </div>
            <!-- <div class="dk" @click="handleClick(item)">打开</div> -->
            <div class="dk" @click="handleClick(item)">
              <div>
                <img src="@/assets/bf.png" alt="" />
              </div>
              <div class="open_text">播放</div>
            </div>
            <!-- <div class="app_size">
              <span>互动次数: {{ item.count }}</span>
              <span>日期: {{ item.start_time_text }}</span>
            </div> -->
            <!-- <div
              class="app_open"
              v-if="item.outlink || item.tolink"
              @click="handleClick(item)"
            >
              <div>
                <img src="../static/images/open_base.png" alt="" />
              </div>
              <div class="open_text">打开</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleGetArticleList } from "../api/index";
import { baseURL } from "../request/index";
export default {
  // name: 'HomeView',
  data() {
    return {
      all: [],
      list: [],
      search_value: "",
      baseURL: baseURL,
    };
  },
  watch: {
    search_value: function (e) {
      this.list = this.all.filter((v) => v.name.indexOf(e) > -1);
    },
  },
  components: {},
  mounted() {
    this.handleGetNews();
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    handleBack() {
      history.back(-1);
    },
    async handleGetNews() {
      const t = new FormData();
      t.append("type", "aps");
      t.append("istree", "1");
      const res = await handleGetArticleList(t);
      if (res.code === 1) {
        this.list = res.data.list;
        this.all = res.data.list;
      } else {
        alert("请求异常");
      }
    },
    handleClick(item) {
      console.log(item);
      if (item.outlink) {
        // window.open(item.outlink)
        location.href = item.outlink;
        return;
      }
      if (item.tolink) {
        // window.open(item.tolink)
        location.href = item.tolink;
        return;
      }
      this.$router.push(`${item.tolink}?id=${item.id}`);
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
    & > .study_title {
      height: 1.5rem;
      padding-top: 0.5rem;
      position: relative;
      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.8rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }
      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .back_image {
        width: 0.7rem;
        height: 0.7rem;
        position: absolute;
        top: 0.85rem;
        left: 0.5rem;
        object-fit: contain;
      }
      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }
      & > .menu_search {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;
        & > img {
          width: 1rem;
          height: 1rem;
        }
        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          font-size: 0.2rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          margin-right: 0.2rem;
        }
        & > .search_text {
          position: absolute;
          left: 0.29rem;
          font-size: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;
      & > .menu_top_left {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
      & > .menu_top_right {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
    }
    & > .video_content {
      display: flex;
      justify-content: center;
      // align-items: center;
      padding: 0.3rem;
      & > img {
        width: 65%;
      }
      & > .video_desc {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 35%;
        color: #000;
        padding: 0.3rem;
        font-size: 0.3rem;
        background: rgb(10, 219, 252);
        & .title {
          font-weight: bold;
          font-size: 0.4rem;
        }
        & .tag_hot {
          font-size: 0.2rem;
        }
      }
    }
    & > .content_list {
      padding: 0.3rem;
      & > .item {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem 0.8rem 0.3rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        // background: url("../static/images/news_base.png") no-repeat;
        height: 1.5rem;
        overflow: hidden;
        align-items: center;
        background-color: #fff;
        border-radius: 0.5rem;
        & > .item_images {
          width: 2.5rem;
          // height: 1rem;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-right: 0.2rem;
          & > img {
            width: 100%;
            border-radius: 3px;
          }
        }
        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: #333;
          font-weight: bold;
          > .dk {
            position: absolute;
            right: 0;
            bottom: -0.35rem;
            font-size: 0.3rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              // margin-top: 0.1rem;
            }

            & img {
              width: 0.6rem;
            }
          }
          & > .app_name {
            font-size: 0.4rem;
            //   margin-bottom: 0.1rem;
          }
          & > .app_desc {
            font-weight: 500;
            color: #666;
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.3rem;
            // height: 1.8rem;
            margin: 0.2rem 0;
            padding-right: 1.5rem;
            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }
          & > .app_size {
            font-size: 0.2rem;
            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }
          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;
            & > .open_text {
              margin-top: 0.1rem;
            }
            & img {
              width: 0.5rem;
            }
          }
        }
      }
      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url("../static/images/style_k.png") no-repeat;
      }
    }
  }
}

.input {
  width: 90%;
  margin: 0 auto;
  > input {
    background-color: #efeeee;
    width: 100%;
    height: 1rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0.3rem 0.3rem;
    box-sizing: border-box;
    outline: none;
  }
}

.back_image {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  top: 0.85rem;
  left: 0.5rem;
  object-fit: contain;
}
</style>
