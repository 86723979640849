<template>
  <div class="content_index">
    <img
      class="shezImg"
      src="@/static/set.png"
      alt=""
      @click="is_set_show = true"
    />
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">知识考核站</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <!-- 小Tab -->
      <div class="menu_top_content">
        <img
          v-if="is_source"
          src="../static/fh.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <!-- <img src="../static/images/left.png" alt="" class="left_image" /> -->
      </div>
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div
          class="title_content"
          v-for="(item, index) in list"
          :key="index"
          @click="handleGoToInfo(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 遮罩 -->
    <div class="more_div" @click="is_set_show = false" v-if="is_set_show"></div>
    <!-- 设置弹窗 -->
    <diV class="set_shop_moadel" v-if="is_set_show">
      <div class="set_title">设置</div>
      <div class="row_info">
        <div>Pico链接</div>
        <input
          type="text"
          class="input"
          placeholder="请输入"
          v-model="pico_url"
        />
      </div>
      <div class="row_info">
        <div>设置区域</div>
        <input
          type="text"
          class="input"
          placeholder="请输入"
          v-model="region"
        />
      </div>
      <div class="row_info">
        <div>设置KEY</div>
        <input type="text" class="input" placeholder="请输入" v-model="key" />
      </div>
      <div class="isOkbtn" @click="handleset">确 定</div>
    </diV>
  </div>
</template>
<script>
import { handleGetexamGetSort } from '../api/index'
import { baseURL } from '../request/index'
export default {
  // name: 'HomeView',
  data() {
    return {
      list: [],
      is_set_show: false,
      pico_url: '',
      region: '',
      key: '',
      is_source: '',
    }
  },
  watch: {
    pico_url: function (e) {
      localStorage.setItem('pico_url', e)
    },
    region: function (e) {
      localStorage.setItem('region', e)
    },
    key: function (e) {
      localStorage.setItem('key', e)
    },
  },
  components: {},
  mounted() {
    this.handleGetNews()
    // 回显设置数据
    this.pico_url = localStorage.getItem('pico_url') || ''
    this.region = localStorage.getItem('region') || ''
    this.key = localStorage.getItem('key') || ''

    this.is_source = this.$route.query.is_source
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format('YYYY-MM-DD')
      }
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url
      }
    },
  },
  methods: {
    handleBack() {
      history.back(-1)
    },
    async handleGetNews() {
      const t = new FormData()
      t.append('istree', '1')
      t.append('uid', this.$route.query.uid || '')
      const res = await handleGetexamGetSort(t)
      if (res.code === 1) {
        this.list = res.data.list
      } else {
        alert('请求异常')
      }
    },
    handleGoToInfo(item) {
      this.$router.push(
        `/select_test?id=${item.id}&uid=${this.$route.query.uid || ''}`
      )
    },
    handleset() {
      location.reload()
    },
  },
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      & > .back_image {
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 0.3rem;
        object-fit: contain;
        position: absolute;
        top: 0.75rem;
        left: 0.3rem;
        z-index: 10;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.8rem;
      }
    }

    & > .content_list {
      padding: 0.3rem;

      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.2rem;
        background-size: 100% 100% !important;
        background: url('../static/images/href_right_base.png') no-repeat;

        & > .item_images {
          width: 3rem;
          height: 3rem;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: rgb(34, 244, 247);

          & > .app_name {
            font-size: 0.4rem;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(233, 21, 33);
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-color: #fff;
        border-radius: 0.3rem;
        font-weight: 500;
        letter-spacing: 0.2rem;
        background-size: 100% 100% !important;
        // background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}

.set_shop_moadel {
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
  width: 90%;
  // height: 40vh;
  border: 1px solid #b0b0b0;
  border-radius: 0.5rem;
  background: #fff;
  padding-bottom: 1rem;
  & > .set_title {
    font-size: 20px;
    font-weight: bold;
    padding: 0.4rem 0;
    text-align: center;
    // margin-bottom: 1rem;
  }
  & > .row_info {
    width: 85%;
    background-color: #e3e3e3;
    font-size: 0.4rem;
    display: flex;
    padding: 0 0.3rem;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    & > div {
      // line-height: 2;
      color: #ed4d4a;
      font-weight: bold;
      letter-spacing: 0.05rem;
    }
    & > input {
      padding: 0.2rem;
      font-size: 0.3rem;
    }
  }
  & > .isOkbtn {
    width: 60%;
    color: #fff;
    text-align: center;
    font-size: 0.5rem;
    line-height: 2;
    margin: 0 auto;
    border-radius: 0.7rem;
    font-weight: bold;
    background: linear-gradient(to right, #f0413d, #f47538);
  }
}

.shezImg {
  width: 0.7rem !important;
  height: 0.7rem !important;
  object-fit: cover;
  position: absolute;
  top: 0.35rem;
  right: 0.5rem;
  z-index: 99;
}

.more_div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  background: #000;
  z-index: 99;
}

.input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: transparent;
}
</style>
