<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/images/base_images.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">课件学习</div>
        <img src="../static/images/study_bvase.png" alt="" />
      </div>
      <!-- 小Tab -->
      <div class="search_cotnent">
        <img src="../static/images/back_base.png" alt="" class="back_image" />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search">
          <img src="../static/images/search.png" alt="" />
          <input type="text" placeholder="请输入内容" />
          <!-- <span class="search_text">搜索</span> -->
        </div>
      </div>
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div class="item">
          <div class="item_content">
            <div class="app_name">应用名称</div>
            <div class="app_desc">
              印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍印工艺介绍
            </div>
            <div class="app_size">
              <span>学习次数: 120</span>
              <span>发布日期: 2023-01-01</span>
            </div>
            <div class="app_open">
              <div>
                <img src="../static/images/open_base.png" alt="" />
              </div>
              <div class="open_text">开始学习</div>
            </div>
            <!-- <div class="test_type">试卷类型</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // name: 'HomeView',
  components: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
    & > .study_title {
      position: relative;
      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.6rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: rgb(34, 244, 247);
      }
      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .back_image {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }
      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }
      & > .menu_search {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;
        & > img {
          width: 1rem;
          height: 1rem;
        }
        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          margin-right: 0.2rem;
        }
        & > .search_text {
          position: absolute;
          left: 0.2rem;
          font-size: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;
      & > .menu_top_left {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
      & > .menu_top_right {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
    }
    & > .content_list {
      padding: 0.3rem;
      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.2rem;
        background-size: 100% 100% !important;
        background: url('../static/images/href_right_base.png') no-repeat;
        & > .item_images {
          width: 3rem;
          height: 3rem;
          & > img {
            width: 100%;
          }
        }
        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: rgb(34, 244, 247);
          & > .app_name {
            font-size: 0.4rem;
            padding-bottom: 0.2rem;
          }
          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;
            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }
          & > .app_size {
            font-size: 0.2rem;
            & > span {
              font-size: 0.2rem;
              margin-right: 0.8rem;
            }
          }
          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;
            & > .open_text {
              margin-top: 0.1rem;
            }
            & img {
              width: 0.5rem;
            }
          }
          & > .test_type {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 0.2rem;
            color: #000;
            padding: 0.1rem;
            border-radius: 2px;
            background: rgb(34, 244, 247);
          }
        }
      }
      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}
</style>
