import { render, staticRenderFns } from "./News.vue?vue&type=template&id=4bc54054&scoped=true"
import script from "./News.vue?vue&type=script&lang=js"
export * from "./News.vue?vue&type=script&lang=js"
import style0 from "./News.vue?vue&type=style&index=0&id=4bc54054&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc54054",
  null
  
)

export default component.exports