<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body" ref="tablescroll">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">数字班前会议</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <!-- 小Tab -->
      <div class="search_cotnent">
        <img
          src="../static/fh.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <!-- <img src="../static/images/left.png" alt="" class="left_image" /> -->
        <!-- <div class="menu_search">
          <img src="../static/images/search.png" alt="" />
          <input type="text" placeholder="请输入内容" v-model="search_value" />
        </div> -->
      </div>
      <div class="input">
        <input type="text" placeholder="请输入内容" v-model="search_value" />
      </div>
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div class="item" v-for="(item, index) in video_list" :key="index">
          <div class="item_images">
            <img :src="handleGetUrl(item.poster_image)" alt="" />
          </div>
          <div class="item_content">
            <div class="app_name">{{ item.name }}</div>
            <div class="app_desc">
              {{ item.description }}
            </div>
            <div class="app_size">
              <span>浏览次数: {{ item.count }}</span>
              <span>日期: {{ handleGetTime(item.createtime) }}</span>
            </div>
            <div class="app_open" @click="handlePlay(item)">
              <div>
                <img src="@/assets/bf.png" alt="" />
              </div>
              <div class="open_text">播放</div>
            </div>
          </div>
        </div>
      </div>

      <a class="back_top" @click.prevent="handleBackTop">Top</a>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { baseURL } from "../request/index";
import { getArticleList } from "../api/index";
export default {
  // name: 'HomeView',
  data() {
    return {
      tab_index: 0,
      video_all: [],
      video_quality_all: [],
      //////////////////
      video_list: [],
      video_quality_list: [],
      search_value: "",
    };
  },
  watch: {
    tab_index: function () {
      this.search_value = "";
      this.video_list = this.video_all;
      this.video_quality_list = this.video_quality_all;
    },
    search_value: function (e) {
      if (this.tab_index === 0) {
        this.video_list = this.video_all.filter((v) => v.name.indexOf(e) > -1);
      }
      if (this.tab_index === 1) {
        this.video_quality_list = this.video_quality_all.filter(
          (v) => v.name.indexOf(e) > -1
        );
      }
    },
  },
  components: {},
  mounted() {
    this.handleGetVideo();
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    async handleGetVideo() {
      const t = new FormData();
      t.append("type", "safe");
      const res = await getArticleList(t);
      this.video_list = res.data.list || [];
      this.video_all = res.data.list || [];
    },
    handlePlay(item) {
      this.$router.push(`/meeting?id=${item.id}&type=${item.type}`);
    },
    handleBack() {
      history.back(-1);
    },
    handleBackTop() {
      this.$nextTick(() => {
        this.$refs.tablescroll.scrollTop = 0;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .back_image {
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 0.3rem;
        object-fit: contain;
        position: absolute;
        top: 0.8rem;
        left: 0.3rem;
      }

      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }

      & > .menu_search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;

        & > img {
          width: 1rem;
          height: 1rem;
        }

        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          font-size: 0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;

      & > .menu_top_left {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }

      & > .menu_top_right {
        width: 49%;

        & > .img_top {
          display: flex;
          justify-content: center;

          & > img {
            // width: 100%;
            width: 1rem;
          }
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;

      & > .item {
        height: 1.9rem;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0.25rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background-color: #fff;
        border-radius: .3rem;
        // background: url("../static/images/href_right_base.png") no-repeat;
        overflow: hidden;

        & > .item_images {
          width: 3rem;
          height: 2rem;
          overflow: hidden;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          flex: 1;
          padding-left: 0.2rem;
          color: #333;

          & > .app_name {
            font-size: 0.4rem;
            font-weight: bold;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            padding: 0.3rem 0;
            box-sizing: border-box;
            font-size: 0.25rem;
            line-height: 0.4rem;
            height: 1.6rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;

            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0.2rem;
            font-size: 0.3rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.6rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url("../static/images/style_k.png") no-repeat;
      }
    }
  }
}

.input {
  width: 90%;
  margin: 0 auto;
  margin-top: -.8rem;
  >input {
    background-color: #efeeee;
    width: 100%;
    height: 1rem;
    border-radius: .5rem;
    border: 0;
    padding: .3rem .3rem;
    box-sizing: border-box;
    outline: none;
  }
}


.back_top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.3rem;
  background: #2ca4ff;
  border-radius: 50%;
  font-size: 0.3rem;
  padding: 0.2rem;
  z-index: 111;
  color: #fff;
}
</style>
