<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">新 闻</div>
        <!-- <img src="../static/images/study_bvase.png" alt="" /> -->
      </div>
      <img
        src="../static/fh.png"
        alt=""
        class="back_image"
        @click="handleBack"
      />
      <!-- 小Tab -->
      <!-- <div class="search_cotnent">
        <img
          src="../static/images/back_base.png"
          alt=""
          class="back_image"
          @click="handleBack"
        />
        <img src="../static/images/left.png" alt="" class="left_image" />
        <div class="menu_search"></div>
      </div> -->
      <div class="menu_top_content"></div>
      <!-- 视频 -->
      <!-- <div class="video_content">
        <img :src="handleGetUrl(top_info.poster_image)" alt="" />
        <div class="video_desc">
          <div>
            <div class="title">{{ top_info.name }}</div>
            <div>
              {{ top_info.description }}
            </div>
          </div>
          <div class="tag_hot">热点</div>
        </div>
      </div> -->
      <!-- 内容列表 -->
      <div class="content_list">
        <!-- 样式1 -->
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleGoTo(item)"
        >
          <div class="item_images">
            <img :src="handleGetUrl(item.poster_image)" alt="" />
          </div>
          <div class="item_content">
            <div class="app_name slh">{{ item.name }}</div>
            <div class="app_desc slh2">{{ item.description }}</div>
            <!-- <div class="app_size">
              <span>浏览量: {{ item.count }}</span>
              <span>发布日期: {{ item.start_time_text }}</span>
            </div> -->
            <div class="app_open"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleGetArticleList } from "../api/index";
import { baseURL } from "../request/index";
export default {
  // name: 'HomeView',
  data() {
    return {
      list: {},
      top_info: {},
    };
  },
  components: {},
  mounted() {
    this.handleGetNews();
    this.handleGetNewsTop();
  },
  computed: {
    handleGetTime() {
      return function (t) {
        return dayjs(t * 1000).format("YYYY-MM-DD");
      };
    },
    handleGetUrl() {
      return function (url) {
        return baseURL + url;
      };
    },
  },
  methods: {
    handleBack() {
      history.back(-1);
    },
    async handleGetNews() {
      const t = new FormData();
      t.append("type", "news");
      t.append("istree", "1");
      const res = await handleGetArticleList(t);
      if (res.code === 1) {
        this.list = res.data.list;
      } else {
        alert("请求异常");
      }
    },
    async handleGetNewsTop() {
      const t = new FormData();
      t.append("type", "news");
      t.append("flag", "top");
      const res = await handleGetArticleList(t);
      if (res.code === 1) {
        const list = res.data.list || [];
        if (list.length > 0) {
          this.top_info = list[0];
        } else {
          // alert('暂无数据')
        }
      } else {
        alert("请求异常");
      }
    },
    handleGoTo(item) {
      if (item.tolink) {
        window.open(item.tolink);
        return;
      }
      if (item.outlink) {
        window.open(item.outlink);
        return;
      }
      this.$router.push(`/courseware?id=${item.id}`);
    },
  },
};
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
    & > .study_title {
      position: relative;
      height: 1.8rem;
      padding-top: 0.5rem;
      & > .title {
        position: absolute;
        width: 100%;
        font-size: 0.9rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }
      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .search_cotnent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .back_image {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }
      & > .left_image {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.8rem;
      }
      & > .menu_search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // padding-right: 0.4rem;
        & > img {
          width: 1rem;
          height: 1rem;
        }
        & > input {
          color: rgb(18, 21, 45);
          background: rgb(10, 219, 252);
          width: 4rem;
          border: none;
          height: 0.5rem;
          padding: 0.1rem;
          outline: none;
          border-radius: 3px;
          padding-left: 0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    & > .menu_top_content {
      display: flex;
      justify-content: space-between;
      align-content: center;
      & > .menu_top_left {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            left: 21%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
      & > .menu_top_right {
        width: 49%;
        & > .img_top {
          display: flex;
          justify-content: center;
          & > img {
            // width: 100%;
            width: 1rem;
          }
        }
        & > .img_footer {
          position: relative;
          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }
          & > img {
            width: 100%;
          }
        }
      }
    }
    & > .video_content {
      display: flex;
      justify-content: center;
      // align-items: center;
      padding: 0.3rem;
      & > img {
        width: 65%;
      }
      & > .video_desc {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 35%;
        color: #000;
        padding: 0.3rem;
        font-size: 0.3rem;
        background: rgb(10, 219, 252);
        & .title {
          font-weight: bold;
          font-size: 0.4rem;
        }
        & .tag_hot {
          font-size: 0.2rem;
        }
      }
    }
    & > .content_list {
      padding: 0.3rem;
      & > .item {
        height: 2rem;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        // line-height: 1;
        padding: 0.4rem 0.3rem 0.3rem 0.3rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        // background: url('../static/images/news_base.png') no-repeat;
        background-color: #fff;
        border-radius: 0.3rem;
        & > .item_images {
          width: 3.5rem;
          // height: 3rem;
          margin-right: 0.2rem;
          height: 2rem;
          overflow: hidden;

          & > img {
            width: 100%;
            border-radius: 3px;
            border-radius: 0.2rem;
          }
        }
        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: #333;
          & > .app_name {
            font-size: 0.4rem;
            font-weight: bold;
            //   margin-bottom: 0.1rem;
          }
          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.25rem;
            line-height: 0.4rem;
            // height: 1.8rem;
            margin: 0.2rem 0;
            //   padding-right: 1.5rem;
            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }
          & > .app_size {
            font-size: 0.2rem;
            & > span {
              font-size: 0.2rem;
              margin-right: 0.2rem;
            }
          }
          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;
            & > .open_text {
              margin-top: 0.1rem;
            }
            & img {
              width: 0.5rem;
            }
          }
        }
      }
      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url("../static/images/style_k.png") no-repeat;
      }
    }
  }
}

.back_image {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.3rem;
  object-fit: contain;
  position: absolute;
  top: 0.9rem;
  left: 0.3rem;
}
</style>
